import '../css/App.css';
import '../css/index.css';
import ImageContainer from './ImageContainer';
import CaptureViewer from './CaptureViewer';
import { useState, useEffect,useRef } from 'react';
import { useNavigate,useParams } from 'react-router-dom';
import socketIOClient from 'socket.io-client';
import TimerOverlay from './TimerOverlay';
import {Button} from 'reactstrap';
import { isAndroid,isIOS } from 'react-device-detect';
import CreatePassButton from './CreatePassButton';
import Cookies from "js-cookie";
const ENDPOINT = process.env.REACT_APP_API_URL;
function DocumentCapture(props) {
const [checked,setChecked] =useState(null);
  const [showCaptureViewer, setShowCaptureViewer] = useState(true);
  const [idVerified,setIdVerified]= useState(null);
  const [endSession, setEndSession]=useState(null);
  const [uploadUrls,setUploadUrls]=useState(null);
  const [apiKey,setApiKey]=useState(null);
  const [faceOnly, setFaceOnly]=useState(false);
  const [verifySsn, setVerifySsn]=useState(false);
  const sameDeviceRef = useRef(null);
  const redirectRef=useRef(null);
  const deviceRef = useRef('');
  const [invalidMessage, setInvalidMessage]=useState('');
  const [checkMRZ, setCheckMRZ]=useState(false);
  const [mrzData, setMrzData]=useState(null);
  const [retake, setRetake]=useState(false);

  function getMessageByKey(messages1, key) {

    const messageObject = messages1.find((message) => message.hasOwnProperty(key));
    return messageObject ? messageObject[key] : null;
  }
  useEffect(() => {
    if (isAndroid) {
      deviceRef.current = 'android';
    } else if (isIOS) {
      deviceRef.current = 'ios';
    } else {
      deviceRef.current = 'unknown';
    }
  
    console.log('Detected Device:', deviceRef.current);
  }, []);

  const { uniqueId } = useParams();
  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);
    window.onpopstate = () => {
      window.history.go(1);
    };
  }, []);
  function handleRedirect(){
    window.location.href=redirectRef.current;
  }


  // useEffect(() => {
  //   // Check if currentUrl is blank
  //   if (props.currentUrl === '') {
  //     // Set the currentUrl to the current window location
  //     window.location.href= Cookies.get('url'); ;
  //   }
  // }, []);
  

  useEffect(() => {

      const intervalId = setInterval(() => {
          props.setTimeLeft(prevTime => {
              if (prevTime <= 0) {
                  clearInterval(intervalId); // Stop the countdown
                  return 0;
              }
              return prevTime - 1000; // Decrease by one second
          });
          if(props.timeLeft < 60000 && props.timeLeft>=30000) {
            props.setTimeColor("yellow");
          }
          if(props.timeLeft < 30000){
            props.setTimeColor("red");
          }
      }, 1000);

      return () => clearInterval(intervalId); // Cleanup on component unmount
    
  }, [props,props.timeLeft,props.setTimeColor,props.setTimeLeft]);


  useEffect(() => {

    const socket = socketIOClient(ENDPOINT,{
      withCredentials: true,
      transports: ['websocket', 'polling'],
        query: {uniqueId }
  });
    socket.on('connect', () => {
      console.log('Connected to server');
      socket.emit('message', 'Liveness check complete');

      socket.emit('GetScanProps', {uniqueId});
      socket.on('scanprops', (data) => {
       if (data.props && data.props.uploadUrls){
         
        setUploadUrls(data.props.uploadUrls);
       }
       if(data.props && data.props.apiKey) {
        setApiKey(data.props.apiKey);
       }
       if(data.props && data.props.faceonly) {
        setFaceOnly(data.props.faceonly);
       }
       if(data.props && data.props.verifyssn) {
        setVerifySsn(data.props.verifyssn);
       }

      });


      socket.on('updateClients', (data) => {
 
        if (data.uniqueId === uniqueId) {
          // Process the update
          setEndSession(true);
          console.log(`Verification step: ${data.message}`);
          console.log("Data is ", data);
          if (data && (data.verified === true)){
            sameDeviceRef.current = data.samedevice;

            if (data.redirect  && data.samedevice) {
              redirectRef.current=data.redirect;
              socket.disconnect();
  
            } else {
            const event = new CustomEvent('IdDataReady', { detail : {idData : data} });
            document.dispatchEvent(event);
            socket.disconnect();
          }
        } else if (data && data.verified === false){
            socket.disconnect();
            sameDeviceRef.current = data.samedevice;
            setTimeout(function(){

              if(data.redirect && data.samedevice) {
              window.location.href = data.redirect ;
              }
            },1000);
          } else if (data && data.reset){
            socket.disconnect();

           
           
          }
        }
      });   


    if(props.images.detectedImage){
        console.log("socket to be sent");
        socket.emit('licenseImageTaken', {uniqueId});
        console.log("socket sent")
    }
    if(props.firstFaceDescriptor){
      console.log("socket to be sent");
      socket.emit('Liveness check done', {uniqueId });
      console.log("socket sent")

  }

  if(idVerified === true ){
    console.log(props.licenseData);
    socket.emit('LicenseVerified', {uniqueId: uniqueId, data: props.licenseData });
    console.log("socket sent")
     setEndSession(true);

  }

  if(idVerified === false ){
    console.log(props.licenseData);
    socket.emit('LicenseNotVerified', {uniqueId: uniqueId, data: props.licenseData,reason: invalidMessage });
    console.log("socket sent")
    setEndSession(true);
  }


    if(checked ===true && idVerified === null){
      console.log("socket to be sent");
      socket.emit('LicenseChecked', { uniqueId});
      console.log("socket sent")
  }
    });
    return () => {
      socket.disconnect();
    };

  }, [props.images.detectedImage,checked,props.firstFaceDescriptor,uniqueId,idVerified]);

  return (
    <>
   <TimerOverlay timeLeft={props.timeLeft} color={props.timeColor} timeColor={props.timeColor}  visible={false}/>


{endSession && (

<div className="session-starting">
<img className = "logo" src={props.logo} alt="checkid"/>  

{/* {sameDeviceRef.current && <p><div className="init-loader"></div></p> } */}
{idVerified ?  <p style={{margin:'5px'}}>{getMessageByKey(props.messages,"idverified")}</p>: <p style={{margin:'5px'}}>{getMessageByKey(props.messages,"idnotverified")}</p>}
{idVerified? <p style={{margin:'5px'}}><CreatePassButton uniqueId={uniqueId} apiKey={apiKey} device={deviceRef.current} messages={props.messages} color={props.color} backgroundColor={props.backgroundColor}/></p>: ""}
{/* {!idVerified &&  <p style={{ margin: '5px' }}>   <Button tabIndex="0"  aria-label="Start over" style={{color: props.backgroundColor, backgroundColor: props.color,fontSize:'16px', padding:'10px 15px', border:'1px solid #ccc', borderRadius:'border-radius: 4px', margin:'2px 0'}}  onClick={() => window.location.href = props.currentUrl}>Try again</Button></p>} */}
{/* {sameDeviceRef.current  ? 
            <p style={{ margin: '5px' }}>   <Button tabIndex="0"  aria-label="Go to website" style={{color: props.backgroundColor, backgroundColor: props.color,fontSize:'16px', padding:'10px 15px', border:'1px solid #ccc', borderRadius:'border-radius: 4px', margin:'2px 0'}} onClick={handleRedirect}>{getMessageByKey(props.messages,"samedevice")}</Button></p>
            :
            (sameDeviceRef.current===false && <p style={{ margin: '5px' }}>{getMessageByKey(props.messages,"differentdevice")}</p>)
            } */}

{redirectRef.current && <p style={{ margin: '5px' }}>   <Button tabIndex="0"  aria-label="Go to website" style={{color: props.backgroundColor, backgroundColor: props.color,fontSize:'16px', padding:'10px 15px', border:'1px solid #ccc', borderRadius:'border-radius: 4px', margin:'2px 0'}} onClick={handleRedirect}>{getMessageByKey(props.messages,"samedevice")}</Button></p>} 

</div> 
)}
{props.timeLeft<=0 && (
  <div className="session-starting">
     <img className = "logo" src={props.logo} alt="checkid"/>  
   <p style={{margin:'5px'}}>{getMessageByKey(props.messages,"terminated")}</p> 
   {/* <p style={{ margin: '5px' }}>   <Button tabIndex="0"  aria-label="Start over" style={{color: props.backgroundColor, backgroundColor: props.color,fontSize:'16px', padding:'10px 15px', border:'1px solid #ccc', borderRadius:'border-radius: 4px', margin:'2px 0'}}  onClick={() => window.location.href = props.currentUrl}>Try again</Button></p> */}
  </div>
)}

<div className="landscape-warning">
<img className = "logo" src={props.logo} alt="checkid"/>  
<p style={{margin:'5px'}}> {getMessageByKey(props.messages,"landscape")}</p>
</div>


    <CaptureViewer
          showCaptureViewer = { showCaptureViewer }
          setShowCaptureViewer = { setShowCaptureViewer }
          setImages = { props.setImages }
          images = {props.images}
          timeLeft = {props.timeLeft}
          setTimeLeft={props.setTimeLeft}
          timeColor={props.timeColor}
          setTimeColor={props.setTimeColor}
          dl = {props.dl}
          setDl={props.setDl}
          email = {props.email}
          setEmail={props.setEmail}
          messages={props.messages}
          setMessages={props.setMessages}
          color={props.color}
          setColor={props.setColor}
          backgroundColor={props.backgroundColor}
          setBackgroundColor={props.setBackgroundColor}
          logo={props.logo}
          setLogo={props.setLogo}
          checkMRZ={checkMRZ}
          setCheckMRZ={setCheckMRZ}
          mrzData={mrzData}
          setMrzData={setMrzData}
          currentUrl={props.currentUrl}
          setCurrentUrl={props.setCurrentUrl}
          retake={retake}
          setRetake={setRetake}


      /> 
<ImageContainer
        showCaptureViewer = { showCaptureViewer }
        setShowCaptureViewer = { setShowCaptureViewer }
        images = { props.images }
        setImages ={props.setImages}
        initialized = {props.initialized}
        setIntialized = {props.setIntialized}
        firstFaceDescriptor = {props.firstFaceDescriptor}
        setFirstFaceDescriptor = {props.setFirstFaceDescriptor}
        licenseData = {props.licenseData}
        setLicenseData= {props.setLicenseData}
        checked = {checked}
        setChecked ={setChecked}
        uniqueId={uniqueId}
        timeLeft = {props.timeLeft}
        setTimeLeft={props.setTimeLeft}
        timeColor={props.timeColor}
        setTimeColor={props.setTimeColor}
        dl={props.dl}
        setDl={props.setDl}
        idVerified={idVerified}
        setIdVerified={setIdVerified}
        invalidMessage={invalidMessage}
        setInvalidMessage={setInvalidMessage}
        uploadUrls = {uploadUrls}
        setUploadUrls={setUploadUrls}
        email = {props.email}
        setEmail={props.setEmail}
        apiKey={apiKey}
        setApiKey={setApiKey}
        faceOnly={faceOnly}
        setFaceOnly={setFaceOnly}
        verifySsn={verifySsn}
        setVerifySsn={setVerifySsn}
        messages={props.messages}
        setMessages={props.setMessages}
        color={props.color}
        setColor={props.setColor}
        backgroundColor={props.backgroundColor}
        setBackgroundColor={props.setBackgroundColor}
        logo={props.logo}
        setLogo={props.setLogo}
        checkMRZ={checkMRZ}
        setCheckMRZ={setCheckMRZ}
        mrzData={mrzData}
        setMrzData={setMrzData}
        currentUrl={props.currentUrl}
        setCurrentUrl={props.setCurrentUrl}
        retake={retake}
        setRetake={setRetake}
      />

    </>
  );
}

export default DocumentCapture;