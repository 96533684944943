import React, { useEffect, useState } from 'react';
import '../css/TimerOverlay.css'; // Assuming you are styling using an external CSS file
function TimerOverlay({ timeLeft, color, timeColor, visible }) {
  // Calculate minutes and seconds from timeLeft
  const minutes = Math.floor(timeLeft / 60000);
  const seconds = Math.floor((timeLeft % 60000) / 1000);

  // Format time string
  const timeString = `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;

  return (
    <div className="timer-overlay">
      {visible && <div className="timer-circle" style={{ backgroundColor: timeColor }}></div>}
      
      <div className="timer-text" style={{ color: timeColor }}>{timeString}</div>
    </div>
  );
}

export default TimerOverlay;
