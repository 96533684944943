import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'reactstrap';
import QrScanner from 'react-qr-scanner';
import axios from 'axios';
import moment from 'moment';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';
import Webcam from 'react-webcam';

const QRCodeReader = ({ apiKey, faceRequired, qrcodeRequired, logoUrl, ip }) => {
  const [showScanner, setShowScanner] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [result, setResult] = useState('No result');
  const [verificationData, setVerificationData] = useState(null);
  const [devices, setDevices] = useState([]);
  const [selectedDeviceId, setSelectedDeviceId] = useState('');
  const [facingMode, setFacingMode] = useState('environment');
  const [showCamera, setShowCamera] = useState(false);
  const [showSelfieButton, setShowSelfieButton]= useState(true);
  const webcamRef = useRef(null);
  const canvasRef = useRef(null);
  const countdownRef = useRef(3);
  const toggleScanner = () => {
    if (qrcodeRequired) {
      setShowScanner(true);
    } else if (faceRequired) {
      setShowCamera(true);
    }
  };

  const videoConstraints = {
    deviceId: selectedDeviceId ? { exact: selectedDeviceId } : undefined
  };

  useEffect(() => {
    console.log("ipaddress",ip)
    navigator.mediaDevices.enumerateDevices().then((deviceInfos) => {
      const videoDevices = deviceInfos.filter(device => device.kind === 'videoinput');
      setDevices(videoDevices);

      if (videoDevices.length > 0) {
        setSelectedDeviceId(videoDevices[0].deviceId);
      }
    });
  }, []);

  const handleCapture = async () => {
    setShowSelfieButton(false);
    const video = webcamRef.current.video;
    const canvas = canvasRef.current;
    if (video && canvas) {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
      const contextCD = canvas.getContext('2d');
      contextCD.clearRect(0, 0, canvas.width, canvas.height);
        contextCD.font = '200px San-Serif';
        contextCD.fillStyle = 'yellowgreen';
        contextCD.textAlign = 'center';
        contextCD.fillText(countdownRef.current.toString(), canvas.width / 2, canvas.height / 2);
        countdownRef.current--;
      const countdownInterval = setInterval(() => {
        contextCD.clearRect(0, 0, canvas.width, canvas.height);
        contextCD.font = '200px San-Serif';
        contextCD.fillStyle = 'yellowgreen';
        contextCD.textAlign = 'center';
        contextCD.fillText(countdownRef.current.toString(), canvas.width / 2, canvas.height / 2);

        if (countdownRef.current === 0) {
          clearInterval(countdownInterval);
          contextCD.fillStyle = 'rgba(255, 255, 255, 0.2)';
          contextCD.fillRect(0, 0, canvas.width, canvas.height);
          setTimeout(() => {
            contextCD.clearRect(0, 0, canvas.width, canvas.height);
            captureSelfie();
            setShowSelfieButton(true);
          }, 500);
        } else {
          countdownRef.current--;
        }
      }, 1000);
    } else {
      console.error("Video or canvas element is not available.");
    }
  }

  const captureSelfie = async () => {
    const imageSrc = webcamRef.current.getScreenshot();
    countdownRef.current = 3;
    setShowCamera(false);
    setShowLoader(true);
    try {
      const response = await axios.post(
        'https://api.checkid.ai/readerdata',
        {
          guid: result,
          image: imageSrc.split(',')[1]
        },
        {
          headers: {
            Authorization: `Bearer ${apiKey}`,
          },
        }
      );
      console.log(response);
      setVerificationData(response.data.response || false);
      setShowLoader(false);

    } catch (error) {
      console.error('API Error:', error);
      setShowCamera(false);
      setShowLoader(false);
    }
  };

  const handleScan = async (data) => {
    if (data) {
      const guid = data.text;
      setResult(guid);
      setShowScanner(false);
      if (faceRequired) {
        setShowCamera(true);
      } else {
        setShowLoader(true);
        try {
          const response = await axios.post(
            'https://api.checkid.ai/readerdata',
            { "guid": guid },
            {
              headers: {
                Authorization: `Bearer ${apiKey}`,
              },
            }
          );
          console.log(response.data.response);

          setVerificationData(response.data.response || false);

          setShowLoader(false);
        } catch (error) {
          console.error('API Error:', error);
        }
      }
    }
  };

  const handleDeviceChange = (event) => {
    setSelectedDeviceId(event.target.value);
    setShowScanner(false);
    setTimeout(() => setShowScanner(true), 100);
  };

  const handleCameraChange = (event) => {
    setSelectedDeviceId(event.target.value);
    setShowCamera(false);
    setTimeout(() => setShowCamera(true), 100);
  };

  const handleError = (err) => {
    console.error(err);
  };

  const handleBackToScanner = () => {
    setShowLoader(false);
    setVerificationData(null);
    if (qrcodeRequired) {
      setShowScanner(true);
      setShowCamera(false);
    } else if (faceRequired) {
      setShowCamera(true);
      setShowScanner(false);
    }
  };
  const previewStyle = {
    height: '100vh',
    width: '100vw',
    position : 'absolute',
    top:0,
    left:0
  };

  const formatDate = (date) => {
    return moment(date).format('MM/DD/YYYY');
  };

  const convertHeightToInches = (heightInCm) => {
    const heightMatch = heightInCm.match(/(\d+)/);
    if (heightMatch) {
      const height = parseInt(heightMatch[0], 10);
      const inches = height * 0.393701;
      const feet = Math.floor(inches / 12);
      const inchesLeft = Math.round(inches % 12);
      return `${feet} ft ${inchesLeft} in`;
    }
    return heightInCm;
  };

  const renderTableRows = (data) => {
    return Object.entries(data).map(([key, value]) => {
      if (key === 'image' || key === 'idimage' || key === 'verified' || key === 'logo' || key === 'webhook') {
        return null;
      }

      if (moment(value, moment.ISO_8601, true).isValid()) {
        value = formatDate(value);
      }

      if (key.toLowerCase() === 'height') {
        value = convertHeightToInches(value);
      }
      if (typeof value === 'boolean') {
        value = value ? 'Yes' : 'No';
      }
      if (typeof value === 'string') {
        value = value.replace(/\^/g, ' ');
      }
      return (
        <tr key={key}>
          <td style={{ color: "yellowgreen", fontWeight: "bold", fontSize: '1em' }}>{key.replace(/([a-z])([A-Z])/g, '$1 $2')}</td>
          <td style={{ color: "gray", fontWeight: "bold", fontSize: '.8em' }}>{value}</td>
        </tr>
      );
    });
  };

  return (
    <div>
      {!showScanner && verificationData === null && !showLoader && !showCamera && (
        <>
          <Button className="widget-button" onClick={toggleScanner}>Open CheckID Reader</Button>
        </>
      )}

      {showScanner && (
        <div className="fullscreen">
          <div style={{ position: 'absolute', top: 0, width: '100%' }}>
          <QrScanner
            delay={300}
            onError={handleError}
            onScan={handleScan}
            style={previewStyle}
            constraints={{
              video: { deviceId: selectedDeviceId ? { exact: selectedDeviceId } : undefined }
            }}
            className="qr-scanner"
          />
          <select className="camera-button" onChange={handleDeviceChange} value={selectedDeviceId}>
            {devices.map((device, index) => (
              <option key={index} value={device.deviceId}>{device.label.split('(')[0] || `Camera ${index + 1}`}</option>
            ))}
          </select>
          <button onClick={() => setShowScanner(false)} className="close-button">Close</button>
          </div>
        </div>
      )}

      {showCamera && (
        <div className="fullscreen">
          <div style={{ position: 'absolute', top: 0, height:'100%', width: '100%' }}>
          {/* <img className="face-line" src="/outline.png" alt="faceoutline"></img> */}
            <Webcam
              audio={false}
              // height={640}
              // width={480}
              ref={webcamRef}
              videoConstraints={videoConstraints}
              controls={false} // Disable default controls
              playsInline // Adding this can help on iOS
              screenshotFormat="image/jpeg"
              // style={{ position:'absolute', top:0,left:0 }}
            />
            <canvas className="canvas" ref={canvasRef} />
            <select className="camera-button" onChange={handleCameraChange} value={selectedDeviceId}>
            {devices.map((device, index) => (
              <option key={index} value={device.deviceId}>{device.label.split('(')[0] || `Camera ${index + 1}`}</option>
            ))}
          </select>
          </div>
          {/* <div style={{ width: '70%', height: '100%', float: 'right', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'center', textAlign: 'center', paddingBottom: '15em' }}> */}
           {showSelfieButton && <Button style={{ fontSize: '5em' }} className="selfie-button" onClick={handleCapture}>Capture</Button>}
          {/* </div> */}
          <button style={{ fontSize: '1.25em' }} onClick={() => setShowCamera(false)} className="close-button">Close</button>
        </div>
      )}

      {showLoader &&
        <div className="widget-loader-container">
          <div className="widget-loader"></div>
        </div>
      }

      {!showScanner && !showCamera && verificationData === false && (
        <div className="fullscreen" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', backgroundColor: 'white', position: 'relative' }}>
          <p style={{ fontSize: "2em", color: "red" }}>Verification Failed</p>
           <p style={{textAlign:'left'}}><ul><li>Remove any hats, glasses or headphones.</li><li>Only your face should be clearly visible in the camera.</li></ul> </p>
          <Button style={{ fontSize: "2em" }} className="startbutton" onClick={handleBackToScanner}>Try again</Button>
        </div>
      )}

      {!showScanner && verificationData && (
        <div style={{ border: '2px solid yellowgreen', margin: "2em auto", display: 'flex', flexDirection: 'column', alignItems: 'flex-start', textAlign: 'left', padding: '15px', fontSize:'2em' }}>

          <img src={logoUrl} alt="Logo" style={{ width: '200px', margin: '10px auto 20px auto' }} />
          <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: '20px' }}>
              <img src={`data:image/jpeg;base64,${verificationData.image}`} alt="Verification" style={{ width: '6.75em', height: 'auto' }} />
              {verificationData.verified && <FaCheckCircle size="2em" style={{ color: 'yellowgreen', marginTop: '10px' }} />}
            </div>
            <div style={{ flex: 1 }}>
              {verificationData.idimage && (
                <img src={`data:image/jpeg;base64,${verificationData.idimage}`} alt="ID" style={{ width: '100%', maxWidth: '400px', height: 'auto', marginBottom: '10px' }} />
              )}
              <table style={{ width: '100%' }}>
                <tbody>
                  {renderTableRows(verificationData)}
                </tbody>
              </table>
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px', width: '100%' }}>
            <div style={{ marginRight: '10px', fontSize: '1em'}}>
              <Button  style={{fontSize: '1em'}} className="closeresult-button" onClick={handleBackToScanner}>Close</Button>
            </div>
          </div>
        </div>
      )}
      <div style={{ position:'absolute', bottom:10 , right:10, fontSize: "1em", zIndex:9000, color:"red"}}>{ip}</div>
    </div>
  );
};

export default QRCodeReader;
