import React, { useState, useEffect } from 'react';
import '../css/SSNInput.css'; // Ensure this path is correct for your CSS
import axios from 'axios';

const SSNInput = ({ onVerified, apiKey, uniqueId, fullname, messages }) => {
    const [ssn, setSsn] = useState('');
    const [error, setError] = useState('');
    const [attempts, setAttempts] = useState(0); // Track the number of verification attempts
    const [isVerifying,setIsVerifying]= useState(false);
    const ENDPOINT = process.env.REACT_APP_API_URL;


    function getMessageByKey(messages1, key) {
        console.log(messages1);
        console.log(key);
        const messageObject = messages1.find((message) => message.hasOwnProperty(key));
        return messageObject ? messageObject[key] : null;
      }



    const verifySSN = async () => {
        // Increment attempts on each verification try
        setAttempts(attempts => attempts + 1);
        try {
            const response = await axios.post(ENDPOINT + '/verifyssn', {
                "uniqueid": uniqueId, 
                "name": fullname, 
                "ssn": ssn
            }, {
                headers: {
                    'Authorization': `Bearer ${apiKey}`
                }
            });

            if (response.data && response.data.response) {
                onVerified(true,ssn);
            } else {
                setError(getMessageByKey(messages,"verifyretry"));
                if (attempts >= 2) { // User has already tried 3 times
                    onVerified(false,ssn); // Notify parent component that verification failed after 3 attempts
                }
            }
        } catch (error) {
            alert(error);
            console.error('Verification error:', error);
            setError(getMessageByKey(messages,"verifyretry"));
            if (attempts >= 2) {
                onVerified(false,ssn); // Same as above, after 3 failed attempts
            }
        }
    };

    useEffect(() => {
        setError(getMessageByKey(messages,"enterssn"));
    }, []);

    const handleInputChange = (event) => {
        const value = event.target.value; // This extracts the string value from the input event
        const formattedValue = value.replace(/\D/g, '').slice(0, 9); // Now 'value' is a string, so 'replace' should work
        setSsn(formattedValue);
    };

    const handleSubmit = async () => {
        if (ssn.length < 9) {
            setError(getMessageByKey(messages,"ssnmustbe9"));
            return;
        }
        setIsVerifying(true);
        await verifySSN();
        setIsVerifying(false);
    };

    return (
        <div className='ssn-container'>
            {error && <div className="error-message">{error}</div>}
            <input
                id="ssn-input"
                className="ssn-box"
                tabIndex="0"  
                aria-label="Input 9 digit SSN without spaces or dashes"
                type="tel"
                maxLength="9"
                placeholder={getMessageByKey(messages,"ssndigits")}
                value={ssn}
                onChange={handleInputChange}
                disabled={attempts >= 3} // Disable input if user has already tried 3 times
            />
            <button className="verify-button" tabIndex="0"  aria-label="Verify SSN" onClick={handleSubmit} disabled={ssn.length !== 9 || attempts >= 3 || isVerifying}>
            {getMessageByKey(messages,"verify")}
            </button>
        </div>
    );
};

export default SSNInput;
