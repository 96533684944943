import React from 'react';
import ReactDOM from 'react-dom/client';
import './css/index.css';
import App from './js/App';

window.addEventListener('error', function(event) {
  console.log('Caught by global handler:', event.error);
});
const props = {
  "apiKey": "ae613fa26433406d98d9b49460173268",
  "method": "email",
  "backgroundColor" : "yellowgreen",
  "color": "#3b3a3a",
  "hoverColor" : "#D19C4D",
  // "logo": "https://civic-care-dev.powerappsportals.com/st3-t-2.png",
   "provider":"Stralto Inc."

}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  
    <App {...props} />
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
