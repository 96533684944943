import React, { useState, useEffect, useRef } from 'react';
import '../css/AddressInput.css'; // Ensure proper CSS styling
import axios from 'axios';

const AddressInput = ({ onVerified, apiKey, uniqueId, fullname, streetName, cityName, stateName, zipCode, messages }) => {
    const [street, setStreet] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [postalCode, setPostalCode] = useState('');
    const [error, setError] = useState('');
    const [showInput, setShowInput] = useState(false);
    const streetRef = useRef("Street");
    const cityRef = useRef("City");
    const stateRef = useRef("State");
    const postalCodeRef = useRef("Postal Code");

    const [isVerifying, setIsVerifying] = useState(false);
    const ENDPOINT = process.env.REACT_APP_API_URL;

    function getMessageByKey(messages1, key) {
        const messageObject = messages1.find((message) => message.hasOwnProperty(key));
        return messageObject ? messageObject[key] : null;
    }

    useEffect(() => {
        if (streetName && cityName && stateName && zipCode) {
            setStreet(streetName);
            console.log(streetName);
            setCity(cityName);
            console.log(cityName);
            setState(stateName);
            console.log(stateName);
            setPostalCode(zipCode);
            console.log(zipCode);
            setTimeout(() => { verifyAddress(); }, 2000);
        } else {
        

            streetRef.current = getMessageByKey(messages, "streetaddress") ||"Street Address";
            cityRef.current = getMessageByKey(messages, "city") || "City";
            stateRef.current = getMessageByKey(messages, "state") || "State";
            postalCodeRef.current = getMessageByKey(messages, "zip") || "Postal Code";
            setShowInput(true);
        }
    }, [streetName, cityName, stateName, zipCode]);

    const states = ["AL", "AK", "AZ", "AR", "CA", "CO", "CT", "DE", "FL", "GA",
        "HI", "ID", "IL", "IN", "IA", "KS", "KY", "LA", "ME", "MD",
        "MA", "MI", "MN", "MS", "MO", "MT", "NE", "NV", "NH", "NJ",
        "NM", "NY", "NC", "ND", "OH", "OK", "OR", "PA", "RI", "SC",
        "SD", "TN", "TX", "UT", "VT", "VA", "WA", "WV", "WI", "WY"];

    const verifyAddress = async () => {
        setIsVerifying(true);
        try {
            console.log(fullname);
            const response = await axios.post(ENDPOINT + '/verifyaddress', {
                "uniqueid": uniqueId,
                "full": fullname,
                "a1": street,
                "state": state,
                "postal": postalCode
            }, {
                headers: {
                    'Authorization': `Bearer ${apiKey}`
                }
            });
            
            if (response.data && response.data.response) {
                console.log(response.data.response);
                if (response.data.response.address) {
                    setStreet(response.data.response.street);
                    setCity(response.data.response.city);
                    setState(response.data.response.state);
                    setPostalCode(response.data.response.postalCode);
                }
                onVerified(true, { street, city, state, postalCode });
            } else {
                setError(getMessageByKey(messages, "verifyretry"));
                onVerified(false, {});
            }
        } catch (error) {
            console.error('Verification error:', error);
            setError(getMessageByKey(messages, "verifyretry"));
            onVerified(false, {});
        } finally {
            setIsVerifying(false);
        }
    };

    const handleSubmit = async () => {
        if (!street || !city || !state || !postalCode) {
            setError(getMessageByKey(messages, "fieldsincomplete"));
            return;
        }
        await verifyAddress();
    };

    return (
        <>
            {showInput && (
                <div className='address-container'>
                    {error && <div className="error-message">{error}</div>}
                    <input
                        id="street-input"
                        className="street-input"
                        tabIndex="0"
                        aria-label="Input Street Address"
                        type="text"
                        placeholder={streetRef.current}
                        value={street}
                        onChange={(e) => setStreet(e.target.value)}
                        disabled={isVerifying}
                    />
                    <div>
                        <input
                            id="city-input"
                            className="address-input"
                            type="text"
                            tabIndex="0"
                            aria-label="Input City"
                            placeholder={cityRef.current}
                            value={city}
                            onChange={(e) => setCity(e.target.value)}
                            disabled={isVerifying}
                        />
                        <select
                            id="state-select"
                            className="state-input"
                            tabIndex="0"
                            aria-label="Input State"
                            value={state}
                            onChange={(e) => setState(e.target.value)}
                            disabled={isVerifying}>
                            <option value="">{stateRef.current}</option>
                            {states.map((st) => (
                                <option key={st} value={st}>{st}</option>
                            ))}
                        </select>
                        <input
                            id="postal-code-input"
                            className="address-input"
                            tabIndex="0"
                            aria-label="Input Postal Code"
                            type="text"
                            placeholder={postalCodeRef.current}
                            value={postalCode}
                            onChange={(e) => setPostalCode(e.target.value)}
                            disabled={isVerifying}
                        />
                    </div>
                    <button tabIndex="0" aria-label="Verify address" className="verify-button" onClick={handleSubmit} disabled={!street || !city || !state || !postalCode || isVerifying}>
                        {getMessageByKey(messages, "verify")}
                    </button>
                </div>
            )}
        </>
    );
};

export default AddressInput;
