import { DDV } from "dynamsoft-document-viewer"
export const mobileCaptureViewerUiConfig = {
    type: DDV.Elements.Layout,
    flexDirection: "column",
    children: [
        // {
        //     type: DDV.Elements.Layout,
        //     className: "ddv-capture-viewer-header-mobile",
        //     children: [
        //         {
        //             type: DDV.Elements.CameraResolution,
        //             className: "ddv-capture-viewer-resolution",
        //         },
        //         DDV.Elements.Flashlight,
        //     ],
        // },
        {
        type: DDV.Elements.MainView
        }
        // {
        //     type: DDV.Elements.Layout,
        //     className: "ddv-capture-viewer-footer-mobile",
        //     children: [
        //         DDV.Elements.AutoDetect,
        //         DDV.Elements.AutoCapture,
        //         {
        //             type: DDV.Elements.Capture,
        //             className: "ddv-capture-viewer-captureButton",
        //         },
        //         {   
        //             // Bind click event to "ImagePreview" element
        //             // The event will be registered later.
        //             type: DDV.Elements.ImagePreview,
        //             events:{ 
        //                 click: "showEditViewer",
        //             }
        //         },
        //         DDV.Elements.CameraConvert,
        //     ],
        // },
    ],
};