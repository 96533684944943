import React, { useEffect,useRef } from "react";

const LivenessAnimation = ({ canvasRef, start }) => {
  const animationId = useRef(null);
  const drops = useRef([]);
  
  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const fontSize = 30;
    const columns = Math.floor(canvas.width / fontSize);
    const yPositions = Array.from({ length: columns }, () => Math.random() * canvas.height);

    const drawMatrixEffect = () => {
      ctx.fillStyle = 'rgba(0, 0, 0, 0.1)'; // Transparent background for fading effect
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      ctx.fillStyle ='#9acd32'; // Green color
      ctx.font = `${fontSize}px monospace`;

      yPositions.forEach((y, index) => {
        const text = Math.random() > 0.5 ? '0' : '1';
        const x = index * fontSize;
        ctx.fillText(text, x, y);

        if (y > canvas.height) {
          yPositions[index] = 0;
        } else {
          yPositions[index] = y + fontSize;
        }
      });
    };

    let animationId;

    const animate = (timestamp) => {
      drawMatrixEffect();
      animationId = requestAnimationFrame(animate);
    };

    if (start) {
      
      animationId = requestAnimationFrame(animate);
    } else {
      ctx.clearRect(0, 0, canvas.width, canvas.height); // Clear canvas when animation stops
    }

    return () => {
      if (animationId) {
        cancelAnimationFrame(animationId);
        ctx.clearRect(0, 0, canvas.width, canvas.height); // Clear canvas on component unmount
      }
    };
  }, [start, canvasRef]);

  return null;
};

export default LivenessAnimation;
