import React from 'react';
import { Button } from 'reactstrap';
import axios from 'axios';
// import 'bootstrap/dist/css/bootstrap.min.css';

const CreatePassButton = ({uniqueId,apiKey,device, messages,color,backgroundColor}) => {


  function getMessageByKey(messages1, key) {
    console.log(messages1);
    console.log(key);
    const messageObject = messages1.find((message) => message.hasOwnProperty(key));
    return messageObject ? messageObject[key] : null;
  }

  const createPass = async () => {
    try {

        const responseType = device === 'ios' ? 'blob' : 'json';

      const response = await axios.post(process.env.REACT_APP_API_URL + '/create-pass', {
        uniqueid : uniqueId,
        device: device
      }, {
        headers: {
          'Authorization': `Bearer ${apiKey}`,
          'Content-Type': 'application/json'
        },
        responseType: responseType// Important to get the response as a blob
      });

      if (response.status === 200) {
        if(device === "ios") {
        const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/vnd.apple.pkpass' }));
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = 'pass.pkpass';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
}
        else {
            // Handle Android Google Wallet pass save URL
            const saveUrl = response.data.saveUrl;
            // Create a new window and assign the URL to it
            const newWindow = window.open('', '_blank');
            newWindow.location.assign(saveUrl);

            setTimeout(() => {
                newWindow.close();
              }, 2000); // Adjust the delay as necessary

          }
      } else {
        console.error('Error creating pass:', response.statusText);
      }
    } catch (error) {
      console.error('Error creating pass:', error);
    }
  };




  return (
    <div>
      <Button tabIndex="0"  aria-label="Add pass to wallet" style={{color: color, backgroundColor:backgroundColor, fontSize:'16px', padding:'10px 15px', border:'1px solid #ccc', borderRadius:'border-radius: 4px', margin:'2px 0'}} onClick={createPass}>{getMessageByKey(messages,"getpass")}</Button>
    </div>
  );
};

export default CreatePassButton;
