import React, { useState,useEffect,useRef } from 'react';
import '../css/DigitInput.css'; // Ensure you have this CSS for styling

const DigitInput = ({correctNumber , onVerified, onClose, messages}) => {
    const [digits, setDigits] = useState(['', '', '', '']);
    const [activeIndex, setActiveIndex] = useState(0); // Random start index
    const inputRefs = useRef([useRef(), useRef(), useRef(), useRef()]);
//    const allFilled = digits.every(digit => digit);
  const [attemptCount, setAttemptCount] = useState(0);
    const [error,setError]= useState('');
    const inputs = document.querySelectorAll('.inputs-container input');
const cardBody = document.querySelector('.cardbody');
const webcam = document.querySelector('.webcam');
const canvas = document.querySelector('.canvas');

// inputs.forEach(input => {
//     input.addEventListener('focus', () => {
//         //cardBody.classList.add('cardbody-focused');



//     });

// });


function getMessageByKey(messages1, key) {
    console.log(messages1);
    console.log(key);
    const messageObject = messages1.find((message) => message.hasOwnProperty(key));
    return messageObject ? messageObject[key] : null;
  }

useEffect(() => {
    // Focus the active input box
    setError(getMessageByKey(messages,"code"));
}, []);


useEffect(() => {
    if (inputRefs.current[activeIndex]?.current) {
        inputRefs.current[activeIndex].current.focus();
    }
  
}, [activeIndex]);

const handleTouchStart = (index) => {
    setActiveIndex(index);
    if (inputRefs.current[index]?.current) {
        inputRefs.current[index].current.focus();
    }
};



useEffect(() => {
    inputRefs.current.forEach((ref, index) => {
        if (ref.current) {
            ref.current.addEventListener('touchstart', () => handleTouchStart(index));
        }
    });

    return () => {
        inputRefs.current.forEach((ref, index) => {
            if (ref.current) {
                ref.current.removeEventListener('touchstart', () => handleTouchStart(index));
            }
        });
    };
}, []);



    const verifyNumber = (enteredNumber) => {
        console.log("Digitverified", correctNumber)
        return enteredNumber === String(correctNumber);
    };

    const isSubmitEnabled = digits.every(digit => digit); 

    useEffect(() => {

        const handleSubmit = () => {
            const enteredNumber = digits.join(''); // Join the digits array to form the entered number
            // TODO: Implement your verification logic here
            if (verifyNumber(enteredNumber)) {
                onVerified(true);
                onClose(); // Close the overlay if the number is correct
             //  cardBody.classList.remove('cardbody-focused');
            } else {
                resetDigits(); 
                const left = 3- (attemptCount + 1);
                if(left===0) {
                    onVerified(false);
              //     cardBody.classList.remove('cardbody-focused');
                } else {
                setAttemptCount(attemptCount + 1);

                    const randomIndex = Math.floor(Math.random() * 4); // Random index between 0 and 3
                    setActiveIndex(0);

                inputRefs.current[0].current.focus();
                
                setError(getMessageByKey(messages,"codeincorrect") + left);
            }}
        };
        if (isSubmitEnabled) {
 
          handleSubmit();
        }
      }, [isSubmitEnabled]); // Add handleSubmit to dependency array if it uses useState values or props
      
      // Ensure handleSubmit is properly defined to handle submission logic
      
    const resetDigits = () => {
        setDigits(['', '', '', '']);
        setActiveIndex(0);

    };
    // Function to handle the input change and ensure only numeric input
    const handleInputChange = (index, value) => {
        const newDigits = [...digits];
        newDigits[index] = value;
        setDigits(newDigits);
    
        if (value) {
            const unfilledIndexes = newDigits
                .map((digit, idx) => ({ digit, idx }))
                .filter(({ digit, idx }) => !digit && idx !== index)
                .map(({ idx }) => idx);
    
            if (unfilledIndexes.length > 0) {
                const sequentialIndex = unfilledIndexes[0]; // Get the first unfilled index
                setActiveIndex(sequentialIndex);
            }
        }
    };


  

    return (
        <div>
        <div className='digit-container'>
        {error && <div className="error-message">{error}</div>}
            <div className='inputs-container'>
        {digits.map((digit, index) => (
            <input
                key={index}
                ref={inputRefs.current[index]}
                className={`digit-box ${index !== activeIndex ? 'disabled' : ''}`}
                type="tel"
                maxLength="1"
                value={digit}
                tabIndex="0"  
                aria-label={`Digit input ${index + 1}`}
                onChange={(e) => handleInputChange(index, e.target.value)}
                disabled={index !== activeIndex || isSubmitEnabled}
                onTouchStart={() => handleTouchStart(index)}
                
                
            />
        ))}
        </div>
    
            {/* <button className="verify-button" onClick={handleSubmit} disabled={!isSubmitEnabled}>
                Submit
            </button> */}
    </div>
    </div>
    );
};

export default DigitInput;