// import { CaptureVisionRouter} from "dynamsoft-capture-vision-router"
import { DDV } from "dynamsoft-document-viewer"
// import { LicenseManager } from "dynamsoft-license"
import { mobileCaptureViewerUiConfig } from "./MobileCaptureViewerUIConfig"
import { initDocDetectModule } from "../initDocDetectModule"
import { CoreModule } from "dynamsoft-core"
import {LicenseManager } from "dynamsoft-license"
import "dynamsoft-document-viewer/dist/ddv.css"
import 'dynamsoft-document-normalizer';
import { EnumCapturedResultItemType, DSImageData, OriginalImageResultItem, CapturedResultItem, Point } from "dynamsoft-core";
import { CameraEnhancer, CameraView, DrawingItem, ImageEditorView, DrawingStyleManager } from "dynamsoft-camera-enhancer";
import { CapturedResultReceiver, CaptureVisionRouter } from "dynamsoft-capture-vision-router";
// import IDFrameCanvas from './IDFrameCanvas';
import { useEffect, useRef,useState } from "react"

import {Button, Card,CardBody,CardText,CardTitle,CardSubtitle} from 'reactstrap';
import {FaCheck, FaUndo} from 'react-icons/fa';
//import "../../node_modules/dynamsoft-document-viewer/dist/ddv.css"; 
import "../css/index.css"

// dynamsoft-document-viewer/dist/ddv.css"
let viewer;
const newPageStyle = {
    border: "2px dashed red",
    background: "grey",
    innerHeight: "50px"
};
const newViewerConfig = {
    pageStyle: newPageStyle,
};




function base64ToBlob(base64, contentType) {
  const byteString = atob(base64.split(',')[1]);
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: contentType });
}





    
    // Any other code that needs to run after setting the engineResourcePath
//CaptureVisionRouter.engineResourcePath = '../../node_modules/dynamsoft-capture-vision-router/dist';
export default function CaptureViewer(props) {
    
  const [captureViewer, setCaptureViewer] = useState(null);
  const [initialized,setInitialized]=useState(false);
  const [canvasElement, setCanvasElement] = useState(null);
  const canvasRef = useRef(null);
  const enableButton = useRef(false);
  const imageEditorViewContainerRef = useRef(null);
  const cameraViewContainerRef = useRef(null);
  const normalizedImageContainer = useRef(null);
  const cameraEnhancer = useRef(null);
  const router = useRef(null);
  const [bShowUiContainer, setShowUiContainer] = useState(true);
  const [bShowImageContainer, setShowImageContainer] = useState(false);
  const [bDisabledBtnEdit, setDisabledBtnEdit] = useState(false);
  const [bDisabledBtnNor, setDisabledBtnNor] = useState(true);
  const [bShowLoading, setShowLoading] = useState(true);
  const initializedRef = useRef(false);

  const normalizer = useRef(null);
  const dce = useRef(null);
  const imageEditorView = useRef(null);
  const layer = useRef(null);
  const view = useRef(null);
  const items = useRef([]);
  const image = useRef(null);
  let quads = [];





  const [showFirstImage, setShowFirstImage] = useState(true);

  function getMessageByKey(messages1, key) {
    // console.log(messages1);
    // console.log(key);
    const messageObject = messages1.find((message) => message.hasOwnProperty(key));
    return messageObject ? messageObject[key] : null;
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowFirstImage(false);
    }, 2500); // 5000 milliseconds = 5 seconds

    // Cleanup the timer
    return () => clearTimeout(timer);
  }, [initialized]);




//   useEffect(() => {
//     const handleVisibilityChange = () => {
//         if (document.visibilityState === 'visible') {
//             // The tab has become visible again, resume your tasks
            
//             async function startCapture() {
//               // layer.current.clearDrawingItems();
//               // setDisabledBtnEdit(false);
//               // setDisabledBtnNor(true);
//               setShowImageContainer(false);
//               setShowUiContainer(true);
//              // view.current.getUIElement().style.display = "";
//               await normalizer.current.startCapturing("DetectDocumentBoundaries_Default");
        
//             }

//             setTimeout(() => {
//             startCapture();
//             },1000);
      
          
//             // Start or resume tasks here
//         } else {
//             // The tab is not visible, pause your tasks
//             console.log('Tab is inactive');
//             normalizer.current.stopCapturing();
//             // Pause tasks here
//         }
//     };

//     // Add event listener for visibility change
//     document.addEventListener('visibilitychange', handleVisibilityChange);

//     // Cleanup the event listener on component unmount
//     return () => {
//         document.removeEventListener('visibilitychange', handleVisibilityChange);
//     };
// }, []);


useEffect(() => {
  const videoElement = document.querySelector('dce-component > div[slot="content"] > video');
  const targetElement = document.querySelector('.capturebody');

  if (!videoElement || !targetElement) {
    console.error('Required elements not found');
    return;
  }

  const handleLoadedMetadata = () => {
    console.log('Video metadata loaded');
    targetElement.style.top = `${videoElement.clientHeight + 20}px`;
    targetElement.style.display = 'block';
  };

  // Check if video metadata is already loaded
  if (videoElement.readyState >= 1) {
    handleLoadedMetadata();
  } else {
    // Add event listener for loadedmetadata
    videoElement.addEventListener('loadedmetadata', handleLoadedMetadata);
  }

  // Cleanup function to remove event listener if necessary
  return () => {
    videoElement.removeEventListener('loadedmetadata', handleLoadedMetadata);
  };
}, [initialized]);
  // useEffect(() => {

  //   const videoElement = document.querySelector('dce-component > div[slot="content"] > video');

  //     const targetElement = document.querySelector('.capturebody.card-body');
  //     canvasRef.current = document.querySelector('canvas[id^="ddv-camera-canvas-"]');
  //     setCanvasElement(canvasElement);
 
  //     console.log('Video element:', videoElement); // Debugging line
  //     if (videoElement) {
  //      // alert(videoElement.height);
  //       // canvasRef.current.height= videoElement.clientHeight;
  //       if (targetElement) {
          
  //         targetElement.style.top = `${videoElement.clientHeight + 20}px`;
          
          
  //     }     
      
  //   }
  //   targetElement.style.display = `block`;
 



  // }, [initialized]);

  useEffect(() => {

    if(props.retake){
      async function startCapture() {
        await normalizer.current.startCapturing("DetectDocumentBoundaries_Default");
        
      }
      props.setRetake(false);
      startCapture();

    }

  }, [props.retake]); 
    useEffect(() => {
      const init = async () => {
         
          try {

              //to see the camera and the view
              //imageeditor - for editing purposes otherwise not required
              //layer to draw the quadboxes
            if(!initializedRef.current){
              await LicenseManager.initLicense('f0114uwAAAD/smRHQOrVzaVY/RuqoOiINZtHeBPeQLH0h+EAMbpe0l7EKLl/QQZx0U2/vQRb2KRNyaOoyhTMz46XtI5YrIWBX//U9rkb9/dilZe2X5+M9T6/+OirYoIgEUkADOVACnnrzbTsKq3+omj4anydF;f0068MgAAAAMd6aLIiS6S756zQ0axSnM7b468J2a81Di8bp0/8i68h9zGlu0MdzPPmPcCiGf4jMjeKE+hMBXPPerqh0ELM4c=')
  

              CoreModule.engineResourcePaths = {
                std: "https://cdn.jsdelivr.net/npm/dynamsoft-capture-vision-std@1.0.0/dist/",
                dip: "https://cdn.jsdelivr.net/npm/dynamsoft-image-processing@2.0.30/dist/",
                core: "https://cdn.jsdelivr.net/npm/dynamsoft-core@3.0.33/dist/",
                license: "https://cdn.jsdelivr.net/npm/dynamsoft-license@3.0.40/dist/",
                cvr: "https://cdn.jsdelivr.net/npm/dynamsoft-capture-vision-router@2.0.32/dist/",
                dbr: "https://cdn.jsdelivr.net/npm/dynamsoft-barcode-reader@10.0.21/dist/",
                dce: "https://cdn.jsdelivr.net/npm/dynamsoft-camera-enhancer@4.0.1/dist/",
                ddn: "https://cdn.jsdelivr.net/npm/dynamsoft-document-normalizer@2.0.20/dist/"
              };
              
              
              
              // Preload "BarcodeReader" module for reading barcodes. It will save time on the initial decoding by skipping the module loading.
              CoreModule.loadWasm(['DBR','DDN']).catch((ex) => {
                  let errMsg;
                  if (ex.message?.includes('network connection error')) {
                    errMsg =
                      'Failed to connect to Dynamsoft License Server: network connection error. Check your Internet connection or contact Dynamsoft Support (support@dynamsoft.com) to acquire an offline license.';
                  } else {
                    errMsg = ex.message || ex;
                  }
                  console.error(errMsg);
                });
              initializedRef.current = true;
              }

              view.current = await CameraView.createInstance();
              dce.current = await (cameraEnhancer.current = CameraEnhancer.createInstance(view.current));
             imageEditorView.current = await ImageEditorView.createInstance(imageEditorViewContainerRef.current);
              layer.current = imageEditorView.current.createDrawingLayer();

              normalizer.current = await (router.current = CaptureVisionRouter.createInstance());
              normalizer.current.setInput(dce.current);

             // cameraViewContainerRef.current.appendChild(view.current.getUIElement()); 
              await view.current.setUIElement(document.getElementById('div-ui-container'))

              //setting resolution
              await dce.current.setResolution({width:960, height:1280});

              
              let styleWhileDetecting =  {
                  lineWidth: 10,
                  strokeStyle: props.color || "yellowgreen"
                  }
              let styleAfterIdentifying =  {
                      lineWidth:10,
                      strokeStyle: "red"
                      }
                  

              DrawingStyleManager.updateDrawingStyle(1, styleWhileDetecting
             );
             DrawingStyleManager.updateDrawingStyle(5, styleAfterIdentifying
             );


             //tell the sdk to load normalization settings
              let newSettings = await normalizer.current.getSimplifiedSettings("DetectDocumentBoundaries_Default");
              //if you want to use the image, //based on below change the result item will differ in onCapturedResultReceived
              newSettings.capturedResultItemTypes |= EnumCapturedResultItemType.CRIT_ORIGINAL_IMAGE;
          //    newSettings.DrawingStyleManager
              await normalizer.current.updateSettings("DetectDocumentBoundaries_Default", newSettings);

             //defining the result 
              const resultReceiver = new CapturedResultReceiver();

              resultReceiver.onCapturedResultReceived = (result) => {
            //this will give returns on every frame as we are getting image as well in settings otherwise just use onNormalizedResultReceived
                const originalImage = result.items.filter((item) => item.type === EnumCapturedResultItemType.CRIT_ORIGINAL_IMAGE);
                if (originalImage.length) {
                
                  image.current = originalImage[0].imageData;
                }

                items.current = result.items.filter((item) => item.confidenceAsDocumentBoundary > 70 && item.type === EnumCapturedResultItemType.CRIT_DETECTED_QUAD);
                // if(items.current){
                //   items.current.map(item => {
                //     if(item.confidenceAsDocumentBoundary > 80) {
                //       enableButton.current = true;
                //     } else {
                //       enableButton.current = false;
                //     }
                //   })
                  
                // }
              }
              
              normalizer.current.addResultReceiver(resultReceiver);

              await dce.current.open();
          
              await normalizer.current.startCapturing("DetectDocumentBoundaries_Default");
              console.log("Strated capturing")
              setShowLoading(false);
              setInitialized(true);
              console.log("INITIALIZED>>>>>>>>>>>>>>>")
              view.current.setScanLaserVisible(false); //use this to hide the laser  

          } catch (ex) {
              let errMsg;
              if (ex.message.includes("network connection error")) {
                  errMsg = "Failed to connect to Dynamsoft License Server: network connection error. Check your Internet connection or contact Dynamsoft Support (support@dynamsoft.com) to acquire an offline license.";
              } else {
                  errMsg = ex.message || ex;
              }
              console.error(errMsg);
          }
      }
      init();

      return async () => {
          (await router.current)?.dispose();
          (await cameraEnhancer.current)?.dispose();
          console.log('VideoNormalizer Component Unmount');
      }
  }, []); 

    const playViewer= () =>{
      viewer.capture();
      
    }



    const confirmTheBoundary = () => {
      //this piece of code is to help see image and draw the boundary over the image to manage edit. 
      // if you want to auto normalize, you can skip this part
      //image.current = null;
      // items.current = [];
       layer.current.clearDrawingItems(); 
        if (!dce.current.isOpen() || !items.current.length) return;
        setShowUiContainer(false);
        setShowImageContainer(true);
        imageEditorView.current.setOriginalImage(image.current);
        quads = [];
        for (let i = 0; i < items.current.length; i++) {
            if (items.current[i].type === EnumCapturedResultItemType.CRIT_ORIGINAL_IMAGE) continue;
            const points = items.current[i].location.points;
            const quad = new DrawingItem.QuadDrawingItem({ points });
            quads.push(quad);
            layer.current.addDrawingItems(quads);
        }
        setDisabledBtnEdit(true);
        setDisabledBtnNor(false);
        normalizer.current.stopCapturing();
        normalize();
    }

    const normalize = async () => {
        let seletedItems = imageEditorView.current.getSelectedDrawingItems();
        let quad;
        if (seletedItems.length) {
            quad = seletedItems[0].getQuad();
        } else {
            quad = items.current[0].location;
        }
        const isPointOverBoundary = (point) => {
            if(point.x < 0 || 
            point.x > image.current.width || 
            point.y < 0 ||
            point.y > image.current.height) {
                return true;
            } else {
                return false;
            }
        };
        // if (quad.points.some((point) => isPointOverBoundary(point))) {
        //     alert("The ID could not be normalized because the boundarires could not be determined. Please try again.");
        //     layer.current.clearDrawingItems();
        //     setDisabledBtnEdit(false);
        //     setDisabledBtnNor(true);
        //     setShowImageContainer(false);
        //     setShowUiContainer(true);
        //     view.current.getUIElement().style.display = "";
        //    await normalizer.current.startCapturing("DetectDocumentBoundaries_Default");
        // } else {
        setShowImageContainer(false);
        normalizedImageContainer.current.innerHTML = "";
        let newSettings = await normalizer.current.getSimplifiedSettings("normalize-document");
        console.log("second time settings",newSettings)
        newSettings.roiMeasuredInPercentage = false;
        newSettings.roi.points = quad.points;
        await normalizer.current.updateSettings("normalize-document", newSettings);
        let norRes = await normalizer.current.capture(image.current, "normalize-document");
        if (norRes.items[0]) {
            normalizedImageContainer.current.append(norRes.items[0].toCanvas());
            console.log(image.current)
            const nBlob = await norRes.items[0].toBlob();
            console.log(URL.createObjectURL(nBlob));
            image.current.toCanvas().toBlob((oBlob) => {
              console.log(URL.createObjectURL(oBlob));
            
                
              if(props.checkMRZ){
                const originalMRZImage= URL.createObjectURL(oBlob);
                const detectedMRZImage=URL.createObjectURL(nBlob);
                props.setImages((prevImages) => ({
                    ...prevImages,
                    originalMRZImage,
                    detectedMRZImage
                }));
    
              } else {
              
                function isValidUrl(string) {
                  try {
                    new URL(string);
                    return true;
                  } catch (_) {
                    return false;
                  }
                }
                let faceImage;

                if (isValidUrl(props.images.faceImage)) {
                  faceImage = props.images.faceImage; // Use it as is
                } else {
                  faceImage = URL.createObjectURL(base64ToBlob(props.images.faceImage, 'image/jpeg')); // Convert to URL
                }
              props.setImages({
                originalImage: URL.createObjectURL(oBlob),
                detectedImage: URL.createObjectURL(nBlob),
                faceImage : faceImage
              });
            }
           
             props.setShowCaptureViewer(false);
            })
          

    
          
             
          







        }
        layer.current.clearDrawingItems();
        // props.setImages(null)
     
        setDisabledBtnEdit(false);
        setDisabledBtnNor(true);
      //  setShowUiContainer(true);
        view.current.getUIElement().style.display = "";
       // await normalizer.current.startCapturing("DetectDocumentBoundaries_Default");
    }

    // useEffect(() => {
        
    //   init(props);
     
    
    // // eslint-disable-next-line react-hooks/exhaustive-deps
    // },[]);

    // useEffect(() => {
        
    //     if(props.showCaptureViewer) {
    //         captureViewer?.currentDocument.deleteAllPages();
    //         captureViewer?.play();
    //     } else {
    //         captureViewer?.stop();
    //     }
    // // eslint-disable-next-line react-hooks/exhaustive-deps
    // },[props.showCaptureViewer]);

    return (

//         <div>



            <div className="capture-container" style={props.showCaptureViewer ? {} : { display: 'none' }} id="viewerContainer">
                          {/* {canvasRef.current && <IDFrameCanvas canvasRef={canvasRef} />} */}
                          
                          <div   id="div-ui-container"  ref={cameraViewContainerRef}>
        <div class="dce-video-container" ></div>

</div>
            
            
            <div id="div-image-container" style={{ display: bShowImageContainer ? "block" : "none", width: "100vw", height: "70vh" }} ref={imageEditorViewContainerRef}>
                <div className="dce-image-container" style={{ display:"none", width: "100%", height: "100%" }}></div>
            </div>
            <div id="normalized-result" style={{ display:"none"}} ref={normalizedImageContainer}></div>
                          
                          <Card   color="secondary" >
                          {!initialized && <p><div className="d-loader"></div></p>}
                          {showFirstImage && <img className="idCard" src="/id-front.png" alt="ID Front" />}
                 <CardBody className="capturebody">
    {/* <CardTitle tag="h4">
    <p>Scan the front of your ID</p>
    </CardTitle> */}

    <CardText tag="h5">
      <p> { props.checkMRZ ? getMessageByKey(props.messages,"placemrz") : getMessageByKey(props.messages,"placeid")}</p>
    </CardText>
    <Button tabIndex="0"  aria-label="Capture" className="startbutton" onClick={confirmTheBoundary}>
    {getMessageByKey(props.messages,"capture")||"Capture"}
    </Button>

  </CardBody>
</Card>

{!initialized && <div className="session-starting">
        <img className = "logo" src={props.logo} alt="checkid"/>   
       <p><div className="init-loader"></div></p>
        <p style={{margin:'5px'}}>{getMessageByKey(props.messages,"initializecapture")}</p>
      </div> } 
                {/* </div> */}
                <div><a className="start-over" href={props.currentUrl}>{getMessageByKey(props.messages,"startover")|| "Start over"}</a></div>  
                <div><img className="timer-image" src={props.logo} alt="checkid" /></div>
            </div>







    )
}