import React, { useEffect, useState } from 'react';
import socketIOClient from 'socket.io-client';
// import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import '../css/widget.css';
import {Input,Button, Card,CardBody,CardText,CardTitle,CardSubtitle,Spinner} from 'reactstrap';
import QRCodeReader from './qrcodereader';
//import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';


const ENDPOINT = "https://api.checkid.ai";// Replace with your Azure Function URL
function QRClient(props) {

  //const { executeRecaptcha } = useGoogleReCaptcha();
  const [uniqueId, setUniqueId] = useState(null);
  const [inProgress,setInProgress]=useState(false);
  const [isValid,setisValid]=useState(null);
  const [message,setMessage]=useState(null);
  const [initialized, setInitialized]= useState(null);
  const [clientIp, setClientIp] = useState("");
  const [faceRequired,setFaceRequired]=useState(false);
  const [qrcodeRequired,setQrcodeRequired]=useState(false);
  const [logoUrl, setLogoUrl]=useState("/checkid.png")
  






  const initializeWidget = async (apiKey) => {
    console.log("running widget");
      //if (executeRecaptcha) {
        // const token = await executeRecaptcha('Authenticate');
        axios.post(ENDPOINT + '/authenticatereader',{}, {
          headers: {
            'Authorization': `Bearer ${apiKey}`
          }
        })
          .then(response => {
            console.log(response.data);
            setInitialized(response.data.response.verified)
            if(!response.data.response.verified){

              setMessage("The QR Code Reader could not be initialized");
            } else {

              setFaceRequired(response.data.response.faceRequired);
              setQrcodeRequired(response.data.response.qrcodeRequired);
              if(response.data.response.logo) setLogoUrl(response.data.response.logo)
                if(response.data.response.ip) setClientIp(response.data.response.ip);
            }
            // Handle the response from the server
          })
          .catch(error => {
            console.error('Error:', error);
            setMessage("The QR Code Reader could not be initialized");
            // Handle the error
          });
      //  }
  }

  useEffect(()=>{
console.log("Props are", props)
initializeWidget(props.apiKey);
  
  },[props.apiKey])


  return (
    <div className="idclient">
      <div className="landscape-warning">
        <img className="logo" src="/checkid.PNG" alt="checkid" />
        <p style={{ margin: '5px' }}> For the best experience, please use portrait mode</p>
      </div>
  <div className="idclientbody"  style={{width:'100%'}}>
    {props.title && <h4 style={{margin:'5px'}}>{props.title}</h4>}
    {(!initialized) && <div class="widget-loader" style={{ borderTopColor: props.backgroundColor || undefined}}></div>
 }

{initialized && <QRCodeReader apiKey={props.apiKey} faceRequired={faceRequired} qrcodeRequired={qrcodeRequired} logoUrl={logoUrl} ip={clientIp}/>}
    
{message}

</div>

{/* <GoogleReCaptcha
                    onVerify={verifyRecaptchaCallback}
                  /> */}



      
    </div>
  );
}

export default QRClient;